<template>
    <div class="p-grid">
        <div class="p-col-12">
            <div class="p-d-flex p-ai-center p-jc-between p-flex-column p-flex-lg-row p-mb-1">
                <h5>Template Actions</h5>
                <div>
                    <Button label="Dashboard" class="p-button-sm p-mr-2" @click="goToDashboard" icon="pi pi-th-large" />
                    <Button label="Previous" class="p-button-sm p-mr-1" @click="goToPrevious" icon="pi pi-chevron-left" />
                    <Button label="Next" icon="pi pi-chevron-right" @click="goToNext" class="p-button-sm" iconPos="right" />
                </div>
            </div>
            <div class="card">
                <div>
                    <span class="subtext p-mt-2 p-text-capitalize">
                        <h6>Admin Name : {{ this.localAdminName ? this.localAdminName : '-' }} </h6>
                    </span> 
                </div>
                <DataTable :value="templateactionlist" v-model:selection="selectedRows" :lazy="true" :paginator="true" :rows="30"
                    :totalRecords="totalRecords" :loading="loading" @page="onPage($event)" class="p-datatable-users"
                    data-key="td1" :rowHover="true"
                    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                    currentPageReportTemplate="Showing {first} to {last} of {totalRecords}">
                    <Toolbar>
                        <template v-slot:left>
                            <h5 class="p-m-0">Template Actions</h5>
                        </template>
                        <template v-slot:right>
                            <Button label="Copy to other admin  " icon="pi pi-copy" class="p-mr-2"
                                @click="onSelectWhatsappSettings" style="margin: right 3.5rem;" :disabled="this.selectedRows.length == 0" />
                        </template>
                    </Toolbar>
                    <template #empty> No data found. </template>
                    <template #loading> Loading data Please wait... </template>
                    <!-- <Column header="Sr." headerStyle="width: 15%">
                        <template #body="{ data }">
                            <div>
                                <div>{{ data.td1 }}</div>
                            </div>
                        </template>
                    </Column> -->
                    <Column selectionMode="multiple" headerStyle="width: 5%"></Column>
                    <Column field="name" header="Action Name" headerStyle="width: 50%">
                        <template #body="{ data }">
                            <div class="p-text-capitalize">{{ data.td2 ? data.td2 : 'N/A' }}</div>
                        </template>
                    </Column>
                    <Column header="Unique Id" headerStyle="width: 15%">
                        <template #body="{ data }">
                            <div> {{ data.td3 }}</div>
                        </template>
                    </Column>
                    <Column header="Action" headerStyle="width: 20%">
                        <template #body="{ data }">
                            <Button label="Edit Event" class="p-button-link" @click="templateActionsRedirect(data)" />
                        </template>
                    </Column>
                </DataTable>
                <Toast />
            </div>
        </div>
    </div>
     <!-- Copy Settings dialog start here -->
     <Dialog v-model:visible="isCopyWhatsappSetting" :style="{ width: '600px' }" header="Copy Template Settings"
        :modal="true" class="p-fluid">
        <div class="p-field p-col-12 p-md-9">
            <label for="admin_name">
                Select Admin
            </label>
            <Dropdown v-model="admin_name" :options="adminList" optionLabel="label" appendTo="body"
                class="p-text-capitalize" placeholder="Select Admin" />
        </div>
        <template #footer>
            <Button v-if="!copysettingloader" label="Submit" icon="pi pi-check" class="p-button-text"
                :disabled="admin_name == null || admin_name == ''" @click="onCopySettingSubmit" style="width: 90px" />
            <Button v-show="copysettingloader" icon="pi pi-spin pi-spinner indigo-color" class="p-button-text p-ml-auto"
                style="width: 90px"></Button>
        </template>
    </Dialog>
    <!-- Copy Settings dialog end here -->
</template>
<script>
import ApiService from '../../service/ApiService';
import useValidate from '@vuelidate/core';
import router from '@/router';
import { useRoute } from 'vue-router';
import { required, helpers, numeric, maxLength, minLength } from '@vuelidate/validators';
export default {
    inject: ['global'],
    data() {
        return {
            v$: useValidate(),
            templateactionlist: [],
            page_no: 0,
            totalRecords: 0,
            loading: false,
            addClientPlanStatus: false,
            planfor: '',
            planforList: [],
            plancharges: '',
            plantype: '',
            monthlycost: '',
            planname: '',
            showLoader: false,
            submitted: false,
            plansdata: '',
            planid: 0,
            editheaderflag: 0,
            showloaderpage: false,
            localActionId: '',
            localAdminId: '',
            localAdminName: '',
            isCopyWhatsappSetting: false,
            selectedRows: [],
            copysettingloader: false,
            adminList: [],
            selectedWhatsAppSettings: [],
            admin_name: '',
            items_per_page: 30
        };
    },
    ApiService: null,
    interval: null,
    created() {
        this.ApiService = new ApiService();
    },
    mounted() {
        const route = useRoute();
        this.routeParam = route.params.adminSubId;
        this.localAdminName = localStorage.localAdminName;
        this.loading = true;
        this.getAdminTemplateActions();
        if (localStorage.localActionId) {
            this.localActionId = localStorage.localActionId;
        }
        if (localStorage.localAdminId) {
            this.localAdminId = localStorage.localAdminId;
        }
    },
    watch: {
        localActionId(actionId) {
            localStorage.localActionId = actionId;
        },
        localAdminId(adminId) {
            localStorage.localAdminId = adminId;
        }
    },
    validations() {
        return {
            plancharges: { required: helpers.withMessage('Please select plan charges', required) },
            plantype: { required: helpers.withMessage('Please select plan type', required) },
            monthlycost: { required: helpers.withMessage('Please enter monthly cost', required), numeric: helpers.withMessage('Only numbers are allowed', numeric), minLength: minLength(3), maxLength: maxLength(10) },
            planname: { required: helpers.withMessage('Please enter plan name', required) },
        };
    },
    methods: {
        getAdminTemplateActions() {
            this.loading = true;
            this.ApiService.getAdminTemplateActions({ page_no: this.page_no, items_per_page: this.items_per_page }).then((data) => {
                if (data.status == 200) {
                    this.templateactionlist = data.data;
                    this.totalRecords = data.count;
                } else {
                    this.templateactionlist = '';
                    this.totalRecords = 0;
                }
                this.loading = false;
            });
        },

        onSelectWhatsappSettings() {
            this.ApiService.getAdminListOnlyExceptGivenId({ clientId: this.routeParam }).then((data) => {
                if (data.status == 200) {
                    this.adminList = data.data;
                } else {
                    this.adminList = null;
                }
            });
            this.isCopyWhatsappSetting = true;
        },

        onCopySettingSubmit() {
            this.copysettingloader = true;
            var formData = new FormData();
            if (this.selectedRows) {
                let vals = this.selectedRows.map(function (item) {
                    return item.td3;
                });
                
                this.selectedWhatsAppSettings = vals;
                formData.append("selectedActionIds", this.selectedWhatsAppSettings);
            }
            formData.append("adminId", this.routeParam);
            formData.append("CopyIntoAdminId", this.admin_name.value);
            this.ApiService.copyTemplateActionToOtherAdmin(formData).then((items) => {
                if (items.success == true) {
                    var successMsg = items.message;
                    this.$toast.add({ severity: 'success', summary: 'Successful', detail: successMsg, life: 3000 });
                    this.getAdminTemplateActions();
                    this.copysettingloader = false;
                    this.isCopyWhatsappSetting = false;
                    this.admin_name = "";
                    this.selectedRows = []
                } else {
                    var errorMsg = items.message;
                    this.$toast.add({ severity: 'error', summary: 'Error', detail: errorMsg, life: 3000 });
                }
            });
        },

        onPage(event) {
            this.loading = true;
            this.page_no = event.page;
            this.getAdminTemplateActions();
        },

        templateActionsRedirect(e) {
            router.push({
                name: "listemplateevent",
                // params: { actionId: e.td1 },
            });
            this.localActionId = e.td1;
            this.localAdminId = this.routeParam;
        },

        goToDashboard() {
            router.push({
                name: "admindashboard",
                params: { adminId: this.routeParam },
            });
        },

        goToPrevious() {
            router.push({
                name: "add-debit",
                params: { adminSubId: this.routeParam },
            });
        },

        goToNext() {
            router.push({
                name: "whatsapp-template-settings",
                params: { adminSubId: this.routeParam },
            });
        },

    },
};
</script>
<style scoped lang="scss">
.plan-badge {
    border-radius: 2px;
    padding: 0.25em 0.5rem;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 12px;
    letter-spacing: 0.3px;

    &.status-free {
        background: #c8e6c9;
        color: #256029;
    }

    &.status-paid {
        background: #ffd8b2;
        color: #805b36;
    }
}

.plan-type-badge {
    border-radius: 2px;
    padding: 0.25em 0.5rem;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 12px;
    letter-spacing: 0.3px;

    &.status-standard {
        background: #b3e5fc;
        color: #23547b;
    }

    &.status-premium {
        background: #eccfff;
        color: #694382;
    }
}
</style>
